.reflection {
    background-color: var(--accent-pink);
    margin: 50px 0;
    padding: 30px;
    color: var(--accent-red);
    border: solid 2px var(--accent-red);
}

.reflection a{
    color: var(--accent-red) !important;
    text-decoration: underline !important;
    margin-bottom: 0.9rem;
    font-size: 1rem;
}

.reflection p{
    color: var(--accent-red) !important;
    margin-bottom: 0.9rem;
    font-size: 1rem !important;
}

.ref a:hover {
    color: var(--accent-red) !important;
}