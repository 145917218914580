.activity {
    background-color: var(--accent-orange);
    margin: 50px 0;
    padding: 30px;
    color: var(--accent-red);
    border: var(--accent-red) 2px solid;
}

.activity a{
    color: var(--accent-red) !important;
    text-decoration: underline !important;
    margin-bottom: 0.9rem;
    font-size: 1rem;
}

.activity p{
    color: var(--accent-red) !important;
    margin-bottom: 0.9rem;
    font-size: 1rem !important;
}

.activity a:hover {
    color: var(--accent-red) !important;
}