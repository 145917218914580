
.first-row {
    height: 100vh;
    display: flex;
    width: 100vw;
}

.left-panel {
    flex: 1;
    background-color: var(--dark-green);
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    padding: 40px 60px;
    color: black;
    flex-direction: column;
    text-align: left;
}

.right-panel {
    flex: 4;
    overflow-y: scroll;
}

.container-holder {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

.text-container-md > .text {
    padding-left: 0;
}

.text-container-md > .header {
    border-bottom: 2px solid black;
    padding: 1rem 0 !important;
}

.page-name {
    color: var(--accent) !important;
    margin-bottom: 2rem !important;
}

.left-panel h5 {
    color: var(--bg-colour);
    margin-bottom: 1.2rem !important;
}

.left-panel h5:hover {
    text-decoration: underline;
}

.left-panel a {
    text-decoration: none !important;
}

#card-col-2 {
    max-width: calc(50% - 20px) !important;
    margin: 15px 0;
}

#card-col-3 {
    max-width: calc(33.3333% - 20px) !important;
    margin: 15px 0;
}

.text-end {
    justify-content: flex-end !important;
}

.brand {
    font-size: 1.25rem;
    margin: 0 !important;
    color: white;
    font-family: "Bold";
    text-decoration: none;
    height: auto !important;
}

.brand:hover {
    color: white;
}

.subsection{
    margin: 50px 0;
}