.quote-small {
    width: 65%;
    text-align: right;
    font-size: 1.5rem !important;
}

.quote-small-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 50px 0;
}

.war-table > tr {
    display: flex;
    justify-content: space-between;
}

.war-table td {
    /* width: calc(50% - 20px); */
}

.next-resource {
    background-color: rgb(201, 160, 158);
    color: black;
    height: 100%;
    width: 100%;
    padding: 20px;
}

.next-resource-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.read-more {
    margin-top: 40px;
    background-color: white;
    padding: 18px 28px;
    border-radius: 40px;
    width: 200px;
    justify-content: center;
}

.read-more p {
    margin-bottom: 0;
    color: var(--dark-green) !important;
}

.read-more a {
    color: var(--dark-green) !important;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.read-more-arrow {
    transform: rotate(-40deg);
    color: var(--dark-green);
    font-size: 25px;
    position: relative;
    bottom: -3px;
    text-decoration: none !important;
}

/* .link-color {
    text-decoration: underline !important;
    color: var(--accent) !important;
}

.link-color:hover {
    color: black !important;
    text-decoration: none !important;
} */

.initial-reading {
    background-color: var(--accent-purple);
    margin: 50px 0;
    padding: 20px;
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue)
}

.initial-reading a{
    color: var(--dark-blue) !important;
    text-decoration: underline !important;
    margin-bottom: 0.9rem;
    font-size: 1rem;
}

.initial-reading a:hover {
    color: var(--dark-blue) !important;
}

.additional-reading {
    background-color: var(--accent);
    margin: 50px 0;
    padding: 30px;
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue)
}

.additional-reading a{
    color: var(--dark-blue) !important;
    text-decoration: underline !important;
    margin-bottom: 0.9rem;
    font-size: 1rem;
}

.additional-reading p{
    color: var(--dark-blue) !important;
    margin-bottom: 0.9rem;
    font-size: 1rem !important;
}

.additional-reading a:hover {
    color: var(--dark-blue) !important;
}

.points td{
    flex: 1;
}

.points tr{
    margin: 20px 0;
}