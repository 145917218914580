.cta img {
    /* width: 100px; */
    height: 100px;
    margin: 0 50px 0 0;
}

.cta {
    margin: 50px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.cta tr {
    display: flex;
    align-items: flex-start;
}

.cta td{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.circle-svg {
    width: 100%;
}

.svg-holder {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta p, .cta a{
    font-size: 20px !important;
    color: black !important;
    margin-bottom: 0.5rem !important;
}

.initiatives a {
    color: black !important;
}

.initiatives li {
    margin-bottom: 0.5rem !important;
}

.initiatives > * {
    font-size: 18px !important;
}

/* .cta a{
    font-weight: 600;
} */