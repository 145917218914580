.landing {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.landing > h1 {
  font-size: 4rem;
  margin-top: 20px;
  padding-bottom: 1rem;
}

.arrow {
  /* transform: rotate(90deg); */
  width: 50px;
  margin-top: 60px;
}

.purpose {
  background-color: var(--dark-green);
  color: white;
  width: 100vw;
  padding-top: 29vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.purpose-2 {
  width: 100vw;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.quote {
  font-family: "Bold";
  font-size: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.quote-holder {
  border: 2px solid black;
  padding: 20px;
}

.author {
  font-size: 20px;
  font-family: "Black";
  margin-bottom: 0 !important;
  color: var(--dark-blue);
}

.explain {
  font-family: "Medium" !important;
}

p {
  font-size: 20px !important;
}

h2 {
  font-size: 2rem !important;
  margin-bottom: 1.5rem !important;
}

tr {
  display: flex;
  flex-direction: row;
}

.header {
  flex: 1;
}

.text {
  flex: 2;
  padding-left: 20px;
}

.text-1 {
  padding-right: 15px;
  flex: 1;
}

.text-2 {
  padding-left: 15px;
  flex: 1;
}

.text-color {
  color: var(--dark-green);
}

.activity {
  background-color: var(--COMBO3_turqoise);
}

.activity table {
  width: 100%;
}

.icon > img {
  height: 50px;
  margin-right: 20px;
  margin-bottom: 1.5rem;
}

.personal-reflection {
  background-color: white;
  padding: 50px;
}

.personal-reflection-design {
  background-color: var(--dark-green);
  position: absolute;
  top: -50px;
  left: 100px;
  height: 600px;
  width: 2000px;
  z-index: -1;
}

.personal-reflection-little {
  background-color: white;
  padding: 20px;
}

.accent {
  color: var(--accent);
}

.quote-small-left {
  font-size: 1.5rem !important;
  width: 100%;
}