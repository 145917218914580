.textArea {
    background-color: var(--bg-colour) !important;
    margin: 10px 0;
}

.textArea, .textButton {
    border-radius: 0 !important;
}

.textButton {
    background-color: var(--accent-pink) !important;
    border: 1px solid var(--accent-red) !important;
    padding: 5px 8px !important;
    margin-bottom: 10px !important;
    font-weight: 400 !important;
    color: var(--accent-red) !important;

}