.card-container {
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    /* border-radius: 10px; */
    border: 2px var(--dark-green) solid;
    padding: 20px;
}

.card-container:hover {
    background-color: var(--dark-green);
}

.card-container:hover h5, .card-container:hover p {
    color: white !important;
}

.card-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.card-holder h5 {
    font-family: "Medium";
    color: var(--dark-green);
    text-align: left;
}

.card-holder p {
    margin-bottom: 0 !important;
    font-size: 25px !important;
    color: var(--dark-green);
}

#card-link {
    text-decoration: none;
}

.card-arrow {
    color: var(--dark-green);
}

