:root {
  --bg-colour: rgb(241, 239, 246);
  /* --red: rgb(166,17,30);
  --blue: rgb(32,38,73);
  --purple: rgb(59,0,48);
  --yellow: rgb(217, 126, 19);
  --accent: rgb(236, 90, 84); */
  --dark-green: rgb(58, 80, 183);
  /* --dark-green: rgb(32, 79, 66); */
  --accent-pink: rgb(241, 156, 129);
  --accent-red: rgb(85, 19, 7);
  --accent-yellow: rgb(233, 169, 78);
  --accent: rgb(185, 214, 236 ); /* blue */
  --accent-purple: rgb(198, 197, 240);
  --accent-orange: rgb(235, 203, 176);

  --COMBO1_blue: rgb(11,0,85);
  --COMBO1_light-blue: rgb(98,187,255);
  --COMBO2_orange:rgb(134,33,13);
  --COMBO2_yellow:rgb(239,247,42);
  --COMBO3_green:rgb(8,64,50);
  --COMBO3_turqoise:rgb(163,205,235);
}

@font-face {
  font-family: 'Thin';
  font-weight: 300;
  src: url(./fonts/ZenKakuGothicNew-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Reg';
  font-weight: 400;
  src: url(./fonts/ZenKakuGothicNew-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Medium';
  font-weight: 600;
  src: url(./fonts/ZenKakuGothicNew-Medium.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Spartan-SemiBold';
  font-weight: 600;
  src: url(./fonts/Spartan-SemiBold.ttf) format('truetype');
} */

@font-face {
  font-family: 'Bold';
  font-weight: 700;
  src: url(./fonts/ZenKakuGothicNew-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Black';
  font-weight: 800;
  src: url(./fonts/ZenKakuGothicNew-Black.ttf) format('truetype');
}

body {
  margin: 24;
  font-size: 18px;
  font-family: "Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-colour) !important;
  overflow-x: hidden;
  text-align: justify;
}

h2, h3, h4, h5, h6 {
  font-family: "Bold";
  margin-bottom: 1rem !important;
}

h1 {
  font-family: "Black";
  margin-bottom: 0.25rem;
}

p {
  text-align: justify;
}