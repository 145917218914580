@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  padding: 30px 50px !important;
  font-family: "Bold";
  color: black !important;
  position: fixed !important;
  justify-content: space-between !important;
  width: 100%;
}

/* .nav-container{
  justify-content: space-between;
  position: fixed;
} */

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar-nav, .nav-link{
  color: black !important;
  font-size: 18px !important;
}

#chapters {
  padding-right: 20px !important;
  display: flex;
}

/* #chapters:hover .rect-1{
  transform: rotate(0);
}

#chapters:hover .rect-2{
  transform: translate(0);
}

#chapters:hover .rect-3{
  transform: rotate(0) translate(0);
} */ */

.rect-1 {
  fill: var(--COMBO3_turqoise);
  transform: rotate(8deg);
}

.rect-2 {
  fill: var(--accent);
  transform: translate(10%);
}

.rect-3 {
  fill: var(--dark-green);
  transform: rotate(-4deg) translate(5%, 20%);
}

.rectangle-holder {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.dropdown {
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: -1;
  position: fixed !important;
  top: 0;
  align-items: flex-end;
}

.dropdown > img {
  height: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.dropdown > img:hover {
  cursor: pointer;
}

.dropdown > tr, .dropdown > th, .dropdown > td {
  vertical-align: bottom;
  padding: 30px 20px;
  font-size: 18px;
  flex: 1;
  font-family: "Bold";
}

.dropdown > tr {
  height: 100%;
  padding: 0 !important;
  vertical-align: baseline;
}

/* .intro, .one, .two, .three {
  background-image: linear-gradient(var(--bg-colour), var(--bg-colour));
  background-repeat: no-repeat;
  transition: background-size .5s, color .5s;
  background-position: 0% 50%;
  background-size: 0% 100%;
}

.intro:hover, .one:hover, .two:hover, .three:hover{
  background-size: 100% 100%;
}*/

.intro {
  background-color: var(--accent-orange);
} 

.one {
  background-color: var(--accent);
  /* color: var(--COMBO1_light-blue) */
}

.two {
  background-color: var(--bg-colour);
  /* color: var(--COMBO2_yellow); */
}

.three {
  background-color: var(--accent-purple);
  /* color: var(--COMBO3_turqoise); */
}

.sub {
  font-size: 16px;
}

.theme {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  padding-top: 17%;
}

.theme > a > h3 {
  margin-bottom: 20px !important;
  color: black !important;
}

.theme > a > h5 {
  color: black !important;
}

.theme > a {
  text-decoration: none !important;
  color: black !important;
  margin-bottom: 7px;
  text-align: left;
}

.theme > a:hover{
  text-decoration: underline !important;
  color: black;
}

.text-container {
  width: 65%;
  padding-bottom: 100px;
}

.text-container-md {
  padding: 80px 80px 0 80px;
}

.text-container-wide {
  width: 80%;
  padding-bottom: 50px;
}

.text-container-activity {
  width: 80%;
}

.text-container-100 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.row {
  justify-content: space-between !important;
}

p > a {
  text-decoration: underline !important;
  color: var(--dark-green) !important;
}

#card-row {
  margin: 40px 0 !important;
}